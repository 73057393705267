import React, { FC } from 'react';
import Icon from 'common/IconCustom';
import Button from 'common/Button';
import { ICustomArrow } from '../models';

const PrevArrow: FC<ICustomArrow> = ({ className, onClick, customClassName, ariaLabel }) => {
  const disabled = className.includes('disabled');

  return (
    <Button
      disabled={disabled}
      classes={`${className} ${customClassName}`}
      clickHandler={onClick}
      variant="secondary"
      ariaLabel={ariaLabel}
    >
      <Icon icon="chevron-left" />
    </Button>
  );
};

export default PrevArrow;
